.person {
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(231, 231, 231);
  margin: 50px 25px;
  padding: 0 30px;
  border-radius: 3px 3px 0 0;
  border: 1px solid #a9a9a9;
  border-top: 5px solid rgb(187, 65, 65);
}

.person img {
  width: 100px;
  height: 100px;
  border-radius: 25px;
  margin-top: -50px;
  border: 1px solid rgb(117, 117, 117);
}

.person h3 {
  margin: 30px 0 10px 0;
}

.person label {
  color: #9b9b9b;
}
