h2 {
  text-align: center;
  padding: 20px 0;
  color: #333;
}

h2:after {
  background: transparent;
  bottom: -20px;
  height: 2px;
  width: 120px;
  background-color: rgb(163, 163, 163);
  left: calc(50% - 60px);
  position: relative;
  content: "";
  display: block;
  z-index: -1;
}

section {
  padding: 50px 100px;
  font-size: 22px;
}

@media screen and (max-width: 680px) {
  section {
    padding: 50px;
  }
}

section.no-padding {
  padding: 50px 0;
  padding-bottom: 0;
}

.column-center {
  display: center;
  flex-direction: column;
  align-items: center;
}

a {
  color: rgb(129, 43, 43);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: rgb(87, 29, 29);
}
