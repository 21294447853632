body {
  padding: 0;
  margin: 0;
}

::selection {
  background: rgb(46, 46, 46);
  color: rgb(209, 209, 209);
}

html * {
  font-family: sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap");
