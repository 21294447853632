.border-box {
  box-sizing: border-box;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: black;
  top: 15px;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  /*padding: 10px 40px;*/
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: rgb(243, 243, 243);
  border: 4px solid rgb(187, 65, 65);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid rgb(206, 206, 206);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgb(206, 206, 206);
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid rgb(206, 206, 206);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgb(206, 206, 206) transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: rgb(206, 206, 206);
  position: relative;
  border-radius: 6px;
  margin: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h2 {
  margin-bottom: 0;
}

.content label {
  font-size: 17px;
  color: #656565;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 840px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
    margin-top: 25px;
  }

  /* Full-width containers */
  .container {
    width: calc(100% - 100px);
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid rgb(206, 206, 206);
    border-width: 10px 10px 10px 0;
    border-color: transparent rgb(206, 206, 206) transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }

  .content {
    margin: 37px 0;
  }
}
