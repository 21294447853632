@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap);
body {
  padding: 0;
  margin: 0;
}

::selection {
  background: rgb(46, 46, 46);
  color: rgb(209, 209, 209);
}

html * {
  font-family: sans-serif;
}

header {
  z-index: 9999;
}

header.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
}

header .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

header img.logo {
  height: 100px;
  width: 160px;
  padding-top: 10px
}

header h1 {
  font-family: "Amatic SC", cursive;
  margin: 10px 0;
  font-size: 60px;
}

header .logo > a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

header ul.menu {
  display: flex;
  justify-content: center;
  padding: 25px 0;
  margin: 0;
  width: 100%;
}

header .wrapper {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 840px) {
  header .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  header .wrapper li {
    padding: 10px 0;
  }
  header .wrapper a {
    font-size: 26px;
  }
}

header ul.menu li {
  list-style-type: none;
  font-size: 20px;
}

header a {
  text-decoration: none;
  color: #ffffff !important;
  text-shadow: 3px 3px 15px black;
  font-weight: 500;
  font-size: 26px;
  text-transform: uppercase;
}

header ul.menu li a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  background-color: transparent;
  transition: all 0.3s ease;
  width: 0;
}

header ul.menu li a:hover:after {
  width: 100%;
  left: 0;
  background-color: rgb(240, 240, 240);
  box-shadow: 2px 2px 10px black;
}

header .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  -webkit-animation: fadeInStickyHeader 0.3s linear forwards;
          animation: fadeInStickyHeader 0.3s linear forwards;
}

header .sticky a {
  color: #333 !important;
  text-shadow: none;
  font-size: 22px;
}

header ul.menu.sticky a:hover:after {
  background-color: #333 !important;
  box-shadow: none;
}

@-webkit-keyframes fadeInStickyHeader {
  0% {
    background-color: transparent;
    -webkit-backdrop-filter: blur(0);
            backdrop-filter: blur(0);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
}

@keyframes fadeInStickyHeader {
  0% {
    background-color: transparent;
    -webkit-backdrop-filter: blur(0);
            backdrop-filter: blur(0);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
}

.border-box {
  box-sizing: border-box;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: black;
  top: 15px;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  /*padding: 10px 40px;*/
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: rgb(243, 243, 243);
  border: 4px solid rgb(187, 65, 65);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid rgb(206, 206, 206);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgb(206, 206, 206);
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid rgb(206, 206, 206);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgb(206, 206, 206) transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: rgb(206, 206, 206);
  position: relative;
  border-radius: 6px;
  margin: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h2 {
  margin-bottom: 0;
}

.content label {
  font-size: 17px;
  color: #656565;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 840px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
    margin-top: 25px;
  }

  /* Full-width containers */
  .container {
    width: calc(100% - 100px);
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid rgb(206, 206, 206);
    border-width: 10px 10px 10px 0;
    border-color: transparent rgb(206, 206, 206) transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }

  .content {
    margin: 37px 0;
  }
}

.person {
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(231, 231, 231);
  margin: 50px 25px;
  padding: 0 30px;
  border-radius: 3px 3px 0 0;
  border: 1px solid #a9a9a9;
  border-top: 5px solid rgb(187, 65, 65);
}

.person img {
  width: 100px;
  height: 100px;
  border-radius: 25px;
  margin-top: -50px;
  border: 1px solid rgb(117, 117, 117);
}

.person h3 {
  margin: 30px 0 10px 0;
}

.person label {
  color: #9b9b9b;
}

h2 {
  text-align: center;
  padding: 20px 0;
  color: #333;
}

h2:after {
  background: transparent;
  bottom: -20px;
  height: 2px;
  width: 120px;
  background-color: rgb(163, 163, 163);
  left: calc(50% - 60px);
  position: relative;
  content: "";
  display: block;
  z-index: -1;
}

section {
  padding: 50px 100px;
  font-size: 22px;
}

@media screen and (max-width: 680px) {
  section {
    padding: 50px;
  }
}

section.no-padding {
  padding: 50px 0;
  padding-bottom: 0;
}

.column-center {
  display: center;
  flex-direction: column;
  align-items: center;
}

a {
  color: rgb(129, 43, 43);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: rgb(87, 29, 29);
}

.leading-img {
  width: 100%;
  height: 80vh;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.row-people {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.location-img-full-width {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.location-img-full-width .half {
  width: calc(50% - 40px);
  height: 300px;
  background-size: cover;
  background-position: center center;
  margin: 20px;
  box-shadow: 10px 10px 55px -25px rgba(0, 0, 0, 0.75);
}

.location-img-full-width .full {
  background-position: center center;
  width: 100%;
  height: 350px;
  background-size: cover;
  margin: 20px;
}

.fixed-bg {
  background-attachment: fixed;
}


.location-row-1 div:nth-child(1) {
  width: calc(25% - 40px);
}

.location-row-1 div:nth-child(2) {
  width: calc(75% - 40px);
}

@media screen and (max-width: 870px) {
  .location-row-1 div:nth-child(1) {
    width: 100%;
  }
  .location-row-1 div:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .location-img-full-width .half {
    width: 100%;
  }
}


