header {
  z-index: 9999;
}

header.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
}

header .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

header img.logo {
  height: 100px;
  width: 160px;
  padding-top: 10px
}

header h1 {
  font-family: "Amatic SC", cursive;
  margin: 10px 0;
  font-size: 60px;
}

header .logo > a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

header ul.menu {
  display: flex;
  justify-content: center;
  padding: 25px 0;
  margin: 0;
  width: 100%;
}

header .wrapper {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 840px) {
  header .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  header .wrapper li {
    padding: 10px 0;
  }
  header .wrapper a {
    font-size: 26px;
  }
}

header ul.menu li {
  list-style-type: none;
  font-size: 20px;
}

header a {
  text-decoration: none;
  color: #ffffff !important;
  text-shadow: 3px 3px 15px black;
  font-weight: 500;
  font-size: 26px;
  text-transform: uppercase;
}

header ul.menu li a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  background-color: transparent;
  transition: all 0.3s ease;
  width: 0;
}

header ul.menu li a:hover:after {
  width: 100%;
  left: 0;
  background-color: rgb(240, 240, 240);
  box-shadow: 2px 2px 10px black;
}

header .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  animation: fadeInStickyHeader 0.3s linear forwards;
}

header .sticky a {
  color: #333 !important;
  text-shadow: none;
  font-size: 22px;
}

header ul.menu.sticky a:hover:after {
  background-color: #333 !important;
  box-shadow: none;
}

@keyframes fadeInStickyHeader {
  0% {
    background-color: transparent;
    backdrop-filter: blur(0);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
  }
}
