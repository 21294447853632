.location-img-full-width {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.location-img-full-width .half {
  width: calc(50% - 40px);
  height: 300px;
  background-size: cover;
  background-position: center center;
  margin: 20px;
  box-shadow: 10px 10px 55px -25px rgba(0, 0, 0, 0.75);
}

.location-img-full-width .full {
  background-position: center center;
  width: 100%;
  height: 350px;
  background-size: cover;
  margin: 20px;
}

.fixed-bg {
  background-attachment: fixed;
}


.location-row-1 div:nth-child(1) {
  width: calc(25% - 40px);
}

.location-row-1 div:nth-child(2) {
  width: calc(75% - 40px);
}

@media screen and (max-width: 870px) {
  .location-row-1 div:nth-child(1) {
    width: 100%;
  }
  .location-row-1 div:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .location-img-full-width .half {
    width: 100%;
  }
}

